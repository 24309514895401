import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import '@/utils/filters'
import i18n from './i18n'
import VCalendar from 'v-calendar';
import VueGtag from "vue-gtag";

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GA_ID },
  appName: 'MyReport',
}, router);

Vue.use(VCalendar);

new Vue({
  el: '#app',
  i18n,
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  },
  created() {}
})
