import _ from 'lodash';

class Utils {

  static getNthDate(nthDays, fromDate = new Date()) {
    fromDate.setUTCHours(0, 0, 0);
    fromDate.setDate(fromDate.getDate() + nthDays);

    return fromDate;
  }

  static getNthDateSimple(nthDays = 0, fromDate = new Date()) {
    const nthDateISOString = this.getNthDate(nthDays, fromDate).toISOString();
    return nthDateISOString.split('T')[0];
  }

  static formatSimpleDate(date) {
    if (date instanceof Date) {
      const year = date.getFullYear();
      const month = `0${date.getMonth() + 1}`.slice(-2);
      const day = `0${date.getDate()}`.slice(-2);

      return `${year}-${month}-${day}`;
    }

    return false;
  }

  static addMissingDatesForPeriod(dates, startDate, endDate, key = 'date') {
    let period = [];

    startDate = new Date(startDate || dates[0][key]);
    endDate = new Date(endDate || dates[dates.length - 1][key]);

    const timeDiff = endDate.getTime() - startDate.getTime();
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1;

    for (let i = 0; i < Math.abs(daysDiff); i++) {
      let date = new Date(startDate.valueOf());
      date = new Date(date.setDate(date.getDate() + i));

      const object = { [key]: Utils.formatSimpleDate(date) };
      period.push(object);
    }

    period = _.unionBy(dates, period, key);
    period = _.orderBy(period, [key], ['asc']);

    return period;
  }
}

export default Utils;
