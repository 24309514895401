const { OrderEnum } = require('@caocao-mobility/ccmp-enum');

exports.convertCentsToAmount = (cents) => {
  if (isNaN(cents)) return null;
  if (cents && cents >= 0) {
    const amount = cents / 100;
    return Math.round((amount + Number.EPSILON) * 100) / 100; // Convert with 2 decimals in number type
  }
  return '-';
}

exports.convertAmountToEuros = (amount) => {
  if (isNaN(amount)) return '-';
  return amount.toLocaleString("fr-FR", {style:"currency", currency:"EUR"});
}

exports.convertCentsToEurosWithCurrency = (cents) => {
  if (isNaN(cents)) return '-';

  const amount = this.convertCentsToAmount(cents)
  return this.convertAmountToEuros(amount)
}

exports.formatNumber = (number) => {
  if (isNaN(number)) return '-';
  return number.toLocaleString('fr-FR');
}

exports.formatServiceType = (number) => {
  if (isNaN(number)) return '-';
  return Object.keys(OrderEnum.ServiceType).filter((key) => OrderEnum.ServiceType[key] === number)[0].toLowerCase();
}

exports.formatOrderType = (number) => {
  if (isNaN(number)) return '-';
  return Object.keys(OrderEnum.OrderType).filter((key) => OrderEnum.OrderType[key] === number)[0].toLowerCase();
}

exports.formatNumber = (number) => {
  if (isNaN(number)) return '-';
  return number.toLocaleString('fr-FR');
}

exports.convertMetersToKm = meters => {
  if (meters && meters >= 0) {
    let meters = meters / 1000;
    return meters.toFixed(1);
  }
  return '-';
}

exports.secondToHours = seconds => {
  if (isNaN(seconds)) return null
  return (seconds / 3600).toFixed(2);
}

exports.secondToTime = seconds => {
  let hours = Math.floor(seconds / 60 / 60);
  let minutes = Math.floor(seconds / 60) - (hours * 60);
  return `${hours} h ${minutes} m`
}

exports.toPercent = float => {
  if (isNaN(float)) return null
  return `${(float * 100).toFixed()}%`
}

exports.getShortDayStringForDate = date => {
  return new Date(date).toLocaleString(window.navigator.language, {weekday: 'short'})
}

exports.getShortMonthDayStringForDate = date => {
  return new Date(date).toLocaleString(window.navigator.language, {month: 'short', day: '2-digit'})
}

exports.getTimeStringForDate = date => {
  return new Date(date).toLocaleString(window.navigator.language, {hour: '2-digit', minute: '2-digit', second: '2-digit'})
}
